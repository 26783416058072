import React from 'react';
import { Link } from 'react-router-dom';

const Equizition = () => (
  <>
    <h2 className="subtitle">{process.env.REACT_APP_NAME}</h2>
    <ol className="rules">
      <li>
        {process.env.REACT_APP_NAME} is a general knowledge game in which trust
        in players' honesty is essential. The use of external sources to obtain
        answers is strictly prohibited.
      </li>
      <li>
        The organization reserves the right to ban any player who does not meet
        this criterion.
      </li>
      <li>All new players (rookies) will be placed in the lowest tier.</li>
      <li>
        If a player leaves {process.env.REACT_APP_NAME} and rejoins later, that
        player will be placed in the lowest available tier.
      </li>
      <li>Each tier will have a maximum of 10 players.</li>
      <li>
        Each player will play 20 games per month, starting on the first Monday
        of the month and continuing over the next 19 working days.
        <br />
        Players will compete twice against every other player in their tier and
        twice without an opponent (on the 10th and 20th rounds).
      </li>
      <li>
        Each game consists of eight questions, each covering a different theme.
      </li>
      <li>
        Players may submit answers from 12:00 a.m. (midnight) until 11:59 p.m.
        (Lisbon time) on the respective day.
      </li>
      <li>
        Misspelled answers will be accepted as long as the phonetics are not
        significantly altered and the question does not explicitly require
        correct spelling.
      </li>
      <li>
        For answers involving a person’s name, the surname or the name by which
        the person is best known will suffice unless the question specifies
        otherwise.
        <br />
        If a player provides a correct surname but an incorrect first name, the
        answer will be considered wrong.
      </li>
      <li>
        If multiple answers are provided, any incorrect answer will result in
        the entire response being marked wrong.
      </li>
      <li>
        Answers may be submitted in English or in the original language (e.g.,
        for books, films, etc.), unless otherwise stated.
        <br />
        If the answer is in a language other than English or the original
        language, the language must be indicated in parentheses; otherwise, the
        answer may be marked wrong.
      </li>
      <li>
        In addition to answering questions, each player must assign a score to
        each question (except in rounds 10 and 20). This score represents the
        points the opponent will earn if they answer the question correctly.
      </li>
      <li>
        <span>Points must be distributed as follows per game:</span>
        <ul>
          <li>3 points for 1 question</li>
          <li>2 points for 3 questions</li>
          <li>1 point for 3 questions</li>
          <li>0 points for 1 question</li>
        </ul>
      </li>
      <li>
        The player with the most points in a game is declared the winner and
        earns 3 ranking points.
      </li>
      <li>In the event of a draw, both players earn 2 ranking points.</li>
      <li>The losing player earns 1 ranking point.</li>
      <li>
        <span>
          If a player fails to submit answers, they earn no ranking points, and
          the opponent is declared the winner. The winner’s points are awarded
          as follows:
        </span>
        <ul>
          <li>12 points for 8 correct answers</li>
          <li>10 points for 7 correct answers</li>
          <li>9 points for 6 correct answers</li>
          <li>8 points for 5 correct answers</li>
          <li>6 points for 4 correct answers</li>
          <li>5 points for 3 correct answers</li>
          <li>3 points for 2 correct answers</li>
          <li>2 points for 1 correct answer</li>
          <li>0 points for 0 correct answers</li>
        </ul>
      </li>
      <li>
        On the 10th and 20th rounds, the games will have no opponent, and the
        ranking score is calculated as follows: half a point for each correct
        answer and one point for participating in the round, resulting in a
        maximum of 5 points.
      </li>
      <li>
        At the end of the 20 games, the two lowest-ranked players in each tier
        will be relegated to the next lowest tier (if applicable). The two
        highest-ranked players will be promoted to the next highest tier (if
        applicable).
      </li>
      <li>
        Additionally, if any of the other eight players have more than 3
        forfeits at the end of the 20 games, they will be relegated to the next
        lowest tier (if applicable). Players from the tier below will be
        promoted to fill their places.
      </li>
      <li>
        The top-ranked player in the highest tier at the end of the 20 games is
        declared the {process.env.REACT_APP_NAME} Monthly Champion.
      </li>
      <li>
        <span>
          In the event of a tie, the following criteria will be applied in
          order:
        </span>
        <ol>
          <li>Highest point differential in all games</li>
          <li>Highest total points earned in all games</li>
          <li>Highest number of wins</li>
          <li>Highest number of correctly answered questions</li>
        </ol>
        <span>
          If a tie persists after applying these criteria, the tied players will
          share the same rank.
        </span>
      </li>
    </ol>
    <h2 className="subtitle">Cup</h2>
    <ol className="rules">
      <li>
        Alongside the league, there is a cup competition each season using the
        same quizzes that are played in the league.
      </li>
      <li>
        The cup will consist of as many rounds as necessary to allow
        participation from all players.
      </li>
      <li>
        In each round, a player will either face an opponent or be directly
        qualified for the next round if there are not enough players.
      </li>
      <li>Opponents for each round are determined through a random draw.</li>
      <li>
        On days when a player has a cup game, they must also assign points to
        their cup opponent.
      </li>
      <li>
        <span>
          If a cup game ends in a tie, the following tiebreaker criteria will be
          applied in order:
        </span>
        <ol>
          <li>The player in the lower tier wins</li>
          <li>The player with the lower rank in the previous season wins</li>
        </ol>
        <span>
          If the above criteria cannot be applied (e.g., the player is not in
          any tier or did not participate in the previous season), the most
          recent player to join {process.env.REACT_APP_NAME} wins.
        </span>
      </li>
    </ol>
    <h2 className="subtitle">Special Quizzes</h2>
    <ol className="rules">
      <li>
        As with other games, the use of external sources to obtain answers is
        strictly prohibited.
      </li>
      <li>Special quizzes consist of 12 questions.</li>
      <li>For each correct answer in a special quiz, you earn 20 points.</li>
      <li>You may use up to 5 jokers in each special quiz.</li>
      <li>
        If you use a joker and answer a question correctly, you earn extra
        points based on the percentage of players who answered that question
        incorrectly.
        <br /> <strong>Example:</strong> If you answer a question correctly with
        a joker and 60% of players answered it incorrectly, you earn 80 points
        (20 base points + 60 bonus points).
      </li>
      <li>
        If you use a joker and answer a question incorrectly, you lose 20
        points.
      </li>
    </ol>
    <h2 className="subtitle" id="subscription">
      Subscription
    </h2>
    <p>
      New players are eligible for a one-month free trial.
      <br />
      After the trial period, the subscription price will be based on the
      following table:
    </p>
    <table className="table is-not-fullwidth">
      <thead>
        <tr>
          <th>Duration</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1 month</td>
          <td>€ 4.99/month</td>
        </tr>
        <tr>
          <td>3 months</td>
          <td>€ 4.66/month</td>
        </tr>
        <tr>
          <td>6 months</td>
          <td>€ 4.33/month</td>
        </tr>
        <tr>
          <td>12 months</td>
          <td>€ 3.99/month</td>
        </tr>
      </tbody>
    </table>
    <p>
      Players are guaranteed to play at least 20 games per month, with each game
      consisting of 8 questions, totaling 160 questions per month.
    </p>
    <p>
      Additionally, there will be special quizzes featuring 12 questions each.
    </p>
    <p>
      Subscription payments can be made via PayPal on your{' '}
      <Link to="/account/">account page</Link>.
    </p>
    <p>
      The payment deadline for a given season is the end of the Saturday before
      the season begins. Each monthly season starts on the first Monday of the
      month.
    </p>
  </>
);

export default Equizition;
