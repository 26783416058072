import React, { useState, useEffect, useCallback } from 'react';
import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import ApiRequest from 'utils/ApiRequest';
import Loading from 'components/Loading';
import Error from 'components/Error';
import PaginatedTable from 'components/PaginatedTable';
import Markdown from 'components/Markdown';
import Modal from 'components/Modal';
import { getGenreTranslation } from 'utils/getGenreTranslation';

import classes from './ExternalQuestionPick.module.scss';

const ExternalQuestionPick = ({ genre, grabQuestion }) => {
  const [error, setError] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [genres, setGenres] = useState();
  const [page, setPage] = useState(1);
  const [removeQuestion, setRemoveQuestion] = useState();
  const [removing, setRemoving] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState();
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    ApiRequest.get(`genres`)
      .then(({ data }) => {
        setGenres(data);
      })
      .catch(({ response }) => {
        setError(response?.status);
      });
  }, []);

  const resetPage = useCallback(() => {
    setQuestions();
    ApiRequest.get(`external-questions?used=0&genre=${genre}&page=${page}`)
      .then(({ data }) => {
        setQuestions(data);
      })
      .catch(({ response }) => {
        setError(response?.status);
      });
  }, [genre, page]);

  useEffect(() => {
    resetPage();
  }, [resetPage]);

  const updateItem = (data) => {
    setRemoving(true);
    ApiRequest.patch(`external-questions`, data)
      .then(() => {
        toast.success(<Trans>Pergunta removida com sucesso.</Trans>);
        resetPage();
      })
      .catch(() => {
        toast.error(<Trans>Não foi possível remover a pergunta.</Trans>);
      })
      .finally(() => {
        setRemoveQuestion();
        setRemoving(false);
      });
  };

  const updateQuestion = (question) => {
    setUpdating(true);
    ApiRequest.patch(`external-questions`, {
      id: question.id,
      genre: question.genre,
      used: false,
    })
      .then(() => {
        toast.success(<Trans>Pergunta actualizada com sucesso.</Trans>);
        resetPage();
        setQuestionToEdit();
      })
      .catch(() => {
        toast.error(<Trans>Não foi possível actualizar a pergunta.</Trans>);
      })
      .then(() => {
        setUpdating(false);
      });
  };

  if (error) {
    return <Error status={error} />;
  }

  if (!questions) {
    return <Loading />;
  }

  const subGenres = genres?.reduce((acc, item) => {
    item.subgenres.forEach((subgenre) => {
      acc[subgenre.slug] = subgenre.id;
    });
    return acc;
  }, {});

  return (
    <>
      <Modal
        type="danger"
        open={removeQuestion}
        title={<Trans>Remover pergunta</Trans>}
        body={<Trans>Tens a certeza que queres remover esta pergunta?</Trans>}
        action={() => {
          updateItem(removeQuestion);
        }}
        doingAction={removing}
        onClose={() => setRemoveQuestion()}
      />
      {questionToEdit && (
        <Modal
          type="info"
          open
          title={<Trans>Editar pergunta</Trans>}
          body={
            <div className="field">
              <label className="label">
                <Trans>Tema</Trans>
              </label>
              <div className="control has-icons-left">
                <div className="select">
                  <select
                    value={questionToEdit.genre}
                    onChange={(event) => {
                      const newValue = event.target.value;
                      setQuestionToEdit((prev) => ({
                        ...prev,
                        genre: newValue,
                      }));
                    }}
                  >
                    {Object.entries(subGenres).map(([slug, id]) => (
                      <option value={slug} key={id}>
                        {getGenreTranslation(slug)}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="icon is-small is-left">
                  <i className="fa-fw fa-solid fa-book" />
                </div>
              </div>
            </div>
          }
          action={() => updateQuestion(questionToEdit)}
          doingAction={updating}
          onClose={() => setQuestionToEdit()}
        />
      )}
      {questions.data?.length > 0 && (
        <PaginatedTable
          array={questions.data}
          totalRows={questions.total}
          itemsPerPage={10}
          initialPage={page}
          columns={[
            {
              id: 'formulation',
              label: <Trans>Enunciado</Trans>,
              render: (item) => (
                <div className="content">
                  <Markdown content={item.formulation} />
                </div>
              ),
            },
            {
              id: 'answer',
              label: <Trans>Resposta</Trans>,
              render: (item) => (
                <div className="content">
                  <Markdown content={item.answer} />
                </div>
              ),
            },
            {
              id: 'actions',
              label: <Trans>Acções</Trans>,
              render: (item) => (
                <>
                  <div
                    className={classnames(
                      'buttons',
                      'has-addons',
                      classes.buttons
                    )}
                  >
                    <button
                      className="button"
                      type="button"
                      onClick={() => grabQuestion(item)}
                    >
                      <span className="icon">
                        <i className="fa-fw fa-solid fa-thumbs-up" />
                      </span>
                    </button>
                    <button
                      className="button"
                      type="button"
                      onClick={() => setQuestionToEdit(item)}
                    >
                      <span className="icon">
                        <i className="fa-fw fa-solid fa-pen-to-square" />
                      </span>
                    </button>
                    <button
                      className="button"
                      type="button"
                      onClick={() => {
                        setRemoveQuestion({ id: item.id, used: true });
                      }}
                    >
                      <span className="icon">
                        <i className="fa-fw fa-solid fa-trash" />
                      </span>
                    </button>
                  </div>
                </>
              ),
            },
          ].filter(Boolean)}
          onChange={(newPage) => {
            setPage(newPage);
          }}
          onError={(code) => {
            setError(code);
          }}
        />
      )}
    </>
  );
};

export default ExternalQuestionPick;
